import Vue from 'vue/dist/vue.esm'
import { ready, setScrollToTopEvent } from 'src/utils/helpers'

ready(() => {
  const id_name = 'about-facilities'
  const wrapper = document.getElementById(id_name)

  if (wrapper) {
    const props = JSON.parse(wrapper.getAttribute('data'))
    new Vue({
      el: wrapper,
      data() {
        return {
          selectedPrefecture: '',
          prefectureOptions: props.prefectures.map(v => v[0]),
        }
      },
      mounted: function() {
        setScrollToTopEvent('#to-top-button')
      },
      methods: {
        onChangeFilter(e) {
          this.selectedPrefecture = e.target.value
        },
        showPrefecture(prefecture) {
          if (this.selectedPrefecture === '') {
            return true
          }
          return prefecture === this.selectedPrefecture
        }
      }
    })
  }
})
