<template lang="pug">
  .custom-select(:class="!options.length && 'disabled'")
    .custom-select__trigger(@click="toggleDropdown")
    .custom-select__value(:class="placeholder && !value ? 'has-text-placeholder-color' : ''") {{ this.selectedValue }}
    transition(name="slide-fade")
      ul.custom-select__options(v-on-clickaway="clickedOutside" v-show="state.showDropdown" :style="[maxHeight ? {'max-height': maxHeight + 'px'} : '']")
        li.option(
          v-for="(item, index) in options"
          :key="index"
          @click="selectItem(item.value || item)"
          :class="{selected: value === (item.value ? item.value : item)}"
        ) {{ item.name ? item.name : item }}
</template>


<script>
  import { mixin as clickaway } from 'vue-clickaway';
  export default {
    props: ['options', 'value', 'name', 'placeholder', 'maxHeight'],
    mixins: [ clickaway ],
    data() {
      return {
        state: {
          showDropdown: false
        }
      }
    },
    computed: {
      selectedValue() {
        const filtered = this.options.filter(item => (item.value ? item.value : item ) === this.value)
        let name = this.placeholder || ''
        if (filtered.length !== 0) {
          name = filtered[0].name || filtered[0]
        }
        return name
      }
    },
    methods: {
      toggleDropdown(valueTo = null) {
        setTimeout(() => {
          this.state.showDropdown = valueTo !== null ? valueTo : !this.state.showDropdown
        }, 0)
      },
      clickedOutside() {
        if (this.state.showDropdown) {
          this.toggleDropdown(false)
        }
      },
      selectItem(newValue) {
        this.$emit('select-item', this.name, newValue)
        setTimeout(() => {
          this.toggleDropdown(false)
        }, 80)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/common/variables.scss';
  .custom-select {
    &.disabled {
      pointer-events: none;
      background-color: whitesmoke;
      border: none;
    }
    &__trigger {
      position: absolute;
      height: 35px;
      width: 100%;
      cursor: pointer;
    }
    &__value {
      position: relative;
      left: 8px;
      display: inline-flex;
      height: 100%;
      align-items: center;
      pointer-events: none;
      font-size: 14px;
      padding-right: 40px;
      white-space: nowrap;
      max-width: 88%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__options {
      background-color: #F0F0F0;
      position: absolute;
      min-width: 100%;
      max-width: 100%;
      overflow: auto;
      top: 33px;
      border: 1px solid #D2D2D2;
      border-radius: 2px;
      box-shadow: 0px 2px 7px 0px darken(#D2D2D2, 15%);
      z-index: 1000;
      max-height: 400px;
      .option {
        min-height: 23px;
        font-size: 13px;
        cursor: pointer;
        padding-left: 26px;
        display: inline-flex;
        align-items: center;
        width: 100%;
        position: relative;
        &.selected {
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 3px;
            width: 7px;
            height: 13px;
            border: 2px solid black;
            border-left: none;
            border-top: none;
            transform: translate(5.5px, 1px) rotate(45deg);
            border-radius: 1px;
          }
        }

        &:hover {
          background-color: #3D99FE;
          color: $white;
          &:before {
            border-color: white;
          }
        }
      }
    }
  }
</style>
