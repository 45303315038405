<template lang="pug">
  #app-info-modal.modal.info-modal
    .modal-background.info-modal-background(@click="onToggle")
    .modal-card
      header.modal-card-head(:class="isAdmin ? 'bg-admin' : 'bg-main'")
        .delete-button-wrapper
          button.delete(aria-label='close' @click="onToggle")
        figure.image
          img(:src="logo")
      section.modal-card-body
        p.body-text バージョン：{{ deployVersion }}
        p.body-text(v-if="isAdmin") ビルド：{{ buildTime }}
        p.body-text(v-if="isAdmin") ビルドバージョン：{{ buildVersion }}
</template>

<script>
  import logoForAdmin from "images/logo_white.png"
  import logoForMain from "images/logo_white_navy.png"
  export default {
    props: ['isAdmin', 'deployVersion', 'buildTime', 'buildVersion'],
    data() {
      return {
        logo: this.isAdmin ? logoForAdmin : logoForMain
      }
    },
    methods: {
      onToggle() {
        const elem = document.getElementById('app-info-modal')
        elem.classList.toggle('is-active')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../styles/common/variables.scss";
  @import "../../../styles/common/mixins.scss";
  .info-modal {
    z-index: 100;
    vertical-align: middle;
    .info-modal-background {
      background-color: rgba(9, 30, 66, 0.54);
    }
  }
  .modal-card {
    width: 480px;
    .modal-card-head {
      height: 280px;
      &.bg-main {
        background-color: $dark-purp;
      }
      &.bg-admin {
        background-color: $admin-teal;
      }
      .delete-button-wrapper {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .modal-card-body {
      min-height: 75px;
      .body-text {
        padding-top: 6px;
        font-size: 14px;
        text-align: left;
      }
    }
  }
</style>
