import Vue from 'vue/dist/vue.esm'
import { ready } from 'src/utils/helpers'
import { setCSRFToken } from 'src/utils/authentication'
import ChangeManagerConfirmModal from 'src/components/Modals/ConfirmModal'
import SearchFacilityForNewManagerModal from 'src/components/Modals/SearchFacilityForNewManagerModal'

ready(() => {
  const id_name = 'change-manager-new'
  const wrapper = document.getElementById(id_name)

  if (wrapper) {
    setCSRFToken()
    const props = JSON.parse(wrapper.getAttribute('data'))
    const prefectures = props.prefectures
    const currentUserId = props.currentUserId
    new Vue({
      el: wrapper,
      currentUserId,
      prefectures,
      components: {
        ChangeManagerConfirmModal,
        SearchFacilityForNewManagerModal
      },
      data() {
        return {
          showChangeManagerConfirmModal: false,
          showSearchFacilityModal: false,
          officialFacilityId: props.officialFacilityId,
          selectedFacility: props.selectedFacility,
        }
      },
      computed: {
        computedFacilityIdField() {
          if (this.selectedFacility) {
            return this.selectedFacility.id
          }
          return ''
        },
        isSelectedFacilityNew() {
          if (this.selectedFacility) {
            const { has_manager, has_ic_type } = this.selectedFacility
            return (!has_ic_type && !has_manager)
          }
          return false
        },
        isSelectedFacilityEmptyManager() {
          if (this.selectedFacility) {
            const { has_manager, has_ic_type } = this.selectedFacility
            return (has_ic_type && !has_manager)
          }
          return false
        },
        isSelectedFacilityHasManager() {
          if (this.selectedFacility) {
            const { has_manager, has_ic_type } = this.selectedFacility
            return (has_ic_type && has_manager)
          }
          return true // 初期表示
        },
        selectedFacilityName() {
          if (this.isSelectedFacilityNew) {
            return this.selectedFacility.name += '（未登録）'
          } else if (this.isSelectedFacilityEmptyManager) {
            return this.selectedFacility.name += '（医長不在）'
          } else if (this.isSelectedFacilityHasManager) {
            return this.selectedFacility.name += '（在任中）'
          }
          return null
        },
        isSelectedOwnFacility() {
          if (this.selectedFacility) {
            return parseInt(this.officialFacilityId) === parseInt(this.selectedFacility.id)
          }
          return false
        }
      },
      methods: {
        toggleSearchFacilityModal() {
          this.showSearchFacilityModal = !this.showSearchFacilityModal
        },
        toggleChangeManagerConfrimModal() {
          this.showChangeManagerConfirmModal = !this.showChangeManagerConfirmModal
        },
        onClickSelectOnSearchFacilityModal(e) {
          this.selectedFacility = e
        },
        onClickSubmit() {
          let facilityIdField = document.getElementById('facility_id')
          if (!facilityIdField) {
            facilityIdField = document.getElementById('change_manager_request_facility_id')
          }
          facilityIdField.value = this.computedFacilityIdField

          const form = document.getElementById('form')
          form.submit()
        }
      }
    })
  }
})
