<template lang="pug">
  .modal(:class="{'is-active': showSearchFacilityModal}")
    .modal-background(@click="handleModalClose")
    .modal-card
      header.modal-card-head.has-background-white.has-text-centered
        p.modal-card-title.is-size-6 施設選択
        span.close(aria-label="close" @click="handleModalClose")
      section.modal-card-body
        .columns.is-mobile.search-field-row
          .column.is-2
            span.label-text 都道府県
          .column
            .select-wrapper
              SelectWithDropdown(
                :options="prefectureOptions"
                @select-item="onPrefectureSelectChagne"
                name="prefecture"
                :value="prefecture"
                placeholder="都道府県選択"
              )
        .columns.is-mobile.search-field-row
          .column.is-2
            span.label-text 施設名
          .column
            input(
              class="input"
              name="facilitySearch"
              placeholder="施設名を入力してください"
              autocomplete="off"
              :disabled="!prefecture"
              @input="onInputChange"
              @focus="onInputFocus"
              @keydown.down.prevent="onArrowDown"
              @keydown.up.prevent="onArrowUp"
              @keydown.enter.prevent="onKeyEnter"
              @compositionstart="composing=false"
              @compositionend="composing=false"
              v-model="facilitySearch"
            )
            .suggests-wrapper(v-on-clickaway="onClickOutside" v-show="openSuggestBox")
              .suggests-wrapper-inner(ref="suggestBox")
                Loading(v-if="isFetchingFacilities")
                .suggest-row(
                  v-else-if="facilityOptions.length"
                  v-for="(item, i) in facilityOptions"
                  :key="item.key"
                  class="suggest-row"
                  :class="{ 'is-active': i === activeSuggestRowIndex }"
                  @click="onSelectFacility(item)"
                  @mouseover="activeSuggestRowIndex = i"
                )
                  .facility-info
                    p.facility-name {{ item.name }}
                    p.facility-address {{ item.address }}
                  .joanr-status-info
                    p.joanr-status {{ getJoanrStatus(item) }}
                p.facility-not-found(v-else)
                  | 施設が見つかりませんでした。
                  br
                  | 検索条件を変えて、もう一度検索してください。
        .columns.is-mobile.search-field-row
          .column.is-2
            span.label-text 所在地
          .column
            p {{ this.hospital ? this.hospital.address : '--'}}
        .columns.is-mobile.search-field-row
          .column.is-2
            span.label-text 登録状況
          .column(v-if="this.hospital")
            p {{ getJoanrStatus(this.hospital) }}
      footer.modal-card-foot.has-background-white
        p.error-message(v-show="errors.form" v-html="errors.form")
        button.button(@click="onSelectButtonClick" type="button") 選択
</template>


<script>
  import debounce from 'lodash/debounce'
  import { mixin as clickaway } from 'vue-clickaway'
  // components
  import SelectWithDropdown from '../SelectWithDropdown.vue'
  import ButtonInput from '../ButtonInput.vue'
  import Loading from '../Loading.vue'

  // api
  import { fetchFacilitiesForChangeManager } from '../../utils/api'

  export default {
    components: {
      SelectWithDropdown,
      ButtonInput,
      Loading
    },
    props: [
      'showSearchFacilityModal',
      'prefectures',
      'currentUserId'
    ],
    mixins: [ clickaway ],
    data() {
      return {
        prefectureOptions: this.formatPrefectures(),
        prefecture: '',
        hospital: '',
        facilityOptions: [],
        facilitySearch: '',
        openSuggestBox: false,
        isFetchingFacilities: false,
        activeSuggestRowIndex: -1,
        errors: {
          form: ''
        },
        composing: false
      }
    },
    methods: {
      formatPrefectures() {
        const prefectureOptions = Object.keys(this.prefectures).map(key => {
          return { value: key, name: this.prefectures[key] }
        })
        return prefectureOptions
      },
      handleModalClose() {
        // resetting state on close
        this.errors = { form: '' }
        this.prefecture = ''
        this.hospital = ''
        this.facilitySearch = ''
        this.facilityOptions = []
        this.prefectureFacilities = []
        this.openSuggestBox = false
        this.activeSuggestRowIndex = -1
        this.$emit('toggle-modal')
      },
      onPrefectureSelectChagne(name, value) {
        this.prefecture = value;
        this.hospital = ''
        this.facilitySearch = ''
        this.errors.form = ''
      },
      onSelectButtonClick() {
        const isValid = this.validateForm()
        if (isValid) {
          this.$emit('select-confirm', this.hospital);
          this.handleModalClose()
        }
      },
      onInputFocus() {
        if (this.facilitySearch) {
          setTimeout(() => {
            this.openSuggestBox = true
          }, 100)
        }
      },
      onArrowUp(e) {
        if (this.activeSuggestRowIndex > 0 && this.openSuggestBox) {
          this.activeSuggestRowIndex = this.activeSuggestRowIndex - 1;
          this.handleSuggestBoxScroll()
        }
      },
      onArrowDown(e) {
        if (this.activeSuggestRowIndex < this.facilityOptions.length - 1 && this.openSuggestBox) {
          this.activeSuggestRowIndex = this.activeSuggestRowIndex + 1;
          this.handleSuggestBoxScroll()
        }
      },
      onKeyEnter(e) {
        if (this.activeSuggestRowIndex > -1 && this.openSuggestBox) {
          this.hospital = this.facilityOptions[this.activeSuggestRowIndex]
          this.facilitySearch = this.hospital.name
          this.onSelectFacility(this.hospital)
        }
      },
      handleSuggestBoxScroll() {
        const suggestBox = this.$refs["suggestBox"]
        const currentActiveRow = suggestBox.children[this.activeSuggestRowIndex]
        suggestBox.scrollTop = currentActiveRow.clientHeight * this.activeSuggestRowIndex
      },
      onInputChange: debounce(
        function(event) {
          if (this.composing) return

          const { value, name } = event.target
          this[name] = value
          this.searchFacility(value)
          this.activeSuggestRowIndex = -1
        }, 200),
      async searchFacility(searchWord) {
        if (searchWord) {
          this.isFetchingFacilities = true
          this.openSuggestBox = true
          const fetchedFacilities = await fetchFacilitiesForChangeManager(this.prefecture, searchWord, this.currentUserId)
          if (fetchedFacilities) {
            this.facilityOptions = fetchedFacilities
          }
          this.isFetchingFacilities = false
        } else {
          this.openSuggestBox = false
          this.facilityOptions = []
        }
      },
      onSelectFacility(hospital) {
        this.hospital = hospital
        this.facilitySearch = hospital.name
        this.openSuggestBox = false
        this.facilityOptions = [hospital]
        this.activeSuggestRowIndex = -1
      },
      validateForm() {
        if (!this.hospital) {
          this.errors.form = '施設を選択してください';
          return false;
        }
        return true;
      },
      getJoanrStatus(item) {
        if (!item.has_ic_type) {
          return '未登録'
        } else if (item.has_manager) {
          return '在任中'
        } else if (!item.has_manager){
          return '医長不在'
        } else {
          return ''
        }
      },
      onClickOutside() {
        if (this.openSuggestBox) {
          setTimeout(() => {
            this.openSuggestBox = false
          }, 0)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/common/variables.scss';
  @import '../../../styles/common/mixins.scss';
  .modal {
    .close {
      @include create-close-icon(24px, 3px, $black);
      cursor: pointer;
    }
    .modal-card {
      box-shadow: 8px 8px 5px rgba(#000, 0.3);
      overflow: visible;
      width: 592px;
      max-height: calc(100vh - 50px);
    }
    .modal-card-body {
      padding-left: 32px;
      padding-right: 32px;
      overflow: visible;
      .search-field-row {
        display: flex;
        align-items: center;
        .column {
          .label-text {
            font-size: 15px;
            font-weight: 600;
          }
          .select-wrapper {
            width: 195px;
          }
          .input {
            &:disabled {
              border: 1px solid #E9E9E9;
              background-color: rgba(#E9E9E9, 0.7)
            }
          }
          .suggests-wrapper {
            position: relative;
            .suggests-wrapper-inner {
              height: 245px;
              overflow-y: scroll;
              border: 1px solid $light-grey-2;
              background-color: $white;
              position: absolute;
              z-index: 100;
              width: 100%;
              .suggest-row {
                display: flex;
                padding: 4px 8px;
                cursor: pointer;
                .facility-info {
                  width: 80%;
                  .facility-name {
                    font-size: 14px;
                  }
                  .facility-address {
                    font-size: 12px;
                    color: #666666;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                  }
                }
                .joanr-status-info {
                  width: 20%;
                  .joanr-status {
                    font-size: 14px;
                  }
                }
                &.is-active {
                  background-color: $lighter-grey;
                }
              }
              .facility-not-found {
                text-align: center;
                padding-top: 15px;
                font-size: 14px;
                color: $grey;
              }
            }
          }

        }
      }
      .city-options, .hospital-options {
        padding: 16px 0;

        &:not(:last-child) {
          &.city-options {
            border-bottom: 1px solid $light-grey-2;
          }
        }
        .selected-prefecture, .selected-city {
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    footer {
      justify-content: flex-end;
      border-radius: 0;
      padding-left: 32px;
      padding-right: 32px;
      .error-message {
        font-size: 14px;
        padding-right: 48px;
        flex: 1;
        padding-left: 16px;
        padding-top: 0;
      }
      button {
        @include create-action-button(165px, 40px, $white, $lighter-blue, 16px);
        border-radius: 0;
      }
    }
  }
</style>
