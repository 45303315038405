/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'packs-main-base' %> to the appropriate
// layout file, like app/views/layouts/main_base.html.erb

import 'src/application.js'

import 'styles/main.scss'

import 'src/main/general/manual/base.js'
import 'src/main/general/about/jyutsushiki.js'
import 'src/main/general/about/facilities.js'
import 'src/main/general/faq/faq-item.js'
import 'src/main/general/top/index.js'
import 'src/main/general/change_manager/index.js'

import 'src/main/member/mine/user_procedures/user_procedures.js'
import 'src/main/member/change_manager/new_initiative/new.js'
import 'src/main/member/change_manager/new_initiative/edit.js'
import 'src/main/member/change_manager/current_initiative/new.js'
import 'src/main/member/change_manager/current_initiative/edit.js'
import 'src/main/member/shared/traceability/index.js'
import 'src/main/member/facility_barcode_folders/index.js'
import 'src/main/member/facility_barcode_items/index.js'

import Vue from 'vue/dist/vue.esm'
import jQuery from 'jquery'

import { closeFlashMessage, ready } from 'src/utils/helpers'
import SessionTimeoutModal from "src/components/Modals/SessionTimeoutModal"
import AppInfoModal from 'src/components/Modals/AppInfoModal'
import TraceabilityRenewalModal from 'src/components/Modals/TraceabilityRenewalModal'

// Use jQuery
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

ready(() => {

  const flashCloseButton = document.getElementById('flash-message-close-button')
  if (flashCloseButton) {
    flashCloseButton.addEventListener("click", closeFlashMessage);
  }

  const sessionTimeoutModelId = 'session-timeout-modal-wrapper'
  const sessionTimeoutModalElem = document.getElementById(sessionTimeoutModelId)
  if (sessionTimeoutModalElem) {
    new Vue({
      el: sessionTimeoutModalElem,
      components: {
        SessionTimeoutModal
      }
    })
  }

  const appInfoModalId = 'app-info-modal-wrapper'
  const appInfoModalElem = document.getElementById(appInfoModalId)
  if (appInfoModalElem) {
    new Vue({
      el: appInfoModalElem,
      components: {
        AppInfoModal
      }
    })
  }

  const TraceabilityRenewalModalId = 'traceability-renewal-modal-wrapper'
  const TraceabilityRenewalModalElem = document.getElementById(TraceabilityRenewalModalId)
  if (TraceabilityRenewalModalElem) {
    new Vue({
      el: TraceabilityRenewalModalElem,
      components: {
        TraceabilityRenewalModal
      }
    })
  }
})
