import axios from 'axios'
import { ERROR_MESSAGES } from "./constants"
import { setCSRFToken } from 'src/utils/authentication'

// NOTE:: setting baseURL in utils/axios

export const fetchFacilities = (prefectureId, searchWord, type) => {
  const query = `?pref_code=${prefectureId}&type=${type}&name=${encodeURIComponent(searchWord)}`

  return axios.get(`/ajax/facilities${query}`, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
    .then(res => {
      if (IS_DEVELOPMENT) {
        console.log('res', res)
      }

      if (res.data.success) {
        return res.data.facilities
      }
    })
    .catch(err => {
      if (IS_DEVELOPMENT) {
        console.log('err', err)
      }

      return null
    })
}

export const fetchFacilitiesForChangeManager = (prefCode, q, currentUserId) => {
  const params = {
    pref_code: prefCode,
    current_user_id: currentUserId,
    q: q,
  }
  return axios.get(`/ajax/facilities/fetch_for_change_manager`, { params })
    .then(res => {
      if (IS_DEVELOPMENT) {
        console.log('res', res)
      }

      if (res.data.success) {
        return res.data.facilities
      }
    })
    .catch(err => {
      if (IS_DEVELOPMENT) {
        console.log('err', err)
      }

      return null
    })
}

export const sendFacilityRequest = (params, isFirstFacilityRequest = false) => {
  const url = isFirstFacilityRequest ? '/start/request' : '/settings/facilities'
  return axios.post(url, params, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
    .then(res => {
      if (IS_DEVELOPMENT) {
        console.log('res', res)
      }
      if (res.data.success) {
        return res.data
      } else {
        return null
      }
    })
    .catch(err => {
      return null
    })
}

export const sendChangeManagerRequest = (params) => {
  return axios.post('/start/change_manager_request', params)
    .then(res => {
      if (IS_DEVELOPMENT) {
        console.log('res', res)
      }
      return res.data
    })
    .catch(err => {
      return null
    })
}

export const sendAcceptChangeManagerFromCurrentRequest = () => {
  return axios.put('/resign_manager/update.json')
    .then(res => {
      window.location.reload()
      return null
    })
    .catch(err => {
      return null
    })
}

export const sendDenyChangeManagerFromCurrentRequest = () => {
  return axios.delete('/resign_manager/destroy.json')
    .then(res => {
      window.location.reload()
      return null
    })
    .catch(err => {
      return null
    })
}

export const sendStartFinishRequest = (params) => {
  console.log('sendStartFinishRequest')
  return axios.post('/start/finish', params, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
    .then(res => {
      console.log('res', res);
      console.log(res.data.url);
      window.location.href = res.data.url;
      return true
    })
    .catch(err => {
      return false
    })
}

export const createSheet = (sheetKindId, params) => {
  const url = `/sheet_kinds/${sheetKindId}/sheets`
  return axios.post(url, params, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res =>{
      if (res.data.success) {
        window.location.href = res.data.url;
        return { isSuccess: true, error: null }
      } else {
        return { isSuccess: false, error: res.data.error }
      }
    })
    .catch(err => {
      console.log('err', err);
      return { isSuccess: false, error: null }
    })
}

export const deleteSheetKind = (id) => {
  return axios.delete(`/sheet_kinds/${id}`)
    .then(res => {
      console.log('res', res);
      if (res.data.success) {
        return true
      }
      return false
    })
    .catch(err => {
      console.log('err', err);
      return false
    })
}

export const updateSheetItemOrder = (id, params) => {
  return axios.put(`/sheet_sections/${id}`, params)
    .then(res => {
      console.log('res', res);
      if (res.data.success) {
        window.location.reload()
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.log('err', err);
      return false
    })
}

export const saveCase = (id, params, subId) => {
  const apiUrl = subId ? `/cases/${id}/sub/${subId}` : `/cases/${id}`
  return axios.put(apiUrl, params, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
    .then(res => {
      console.log('res', JSON.parse(JSON.stringify(res)));
      if (res.data.success) {
        if (params.draft) {
          return { isSuccess: true, errors: null }
        } else {
          return { isSuccess: true, errors: null }
        }
      } else {
        return { isSuccess: false, errors: res.data.data_errors }
      }
    })
    .catch(err => {
      console.log('err', err);
      return {
        isSuccess: false,
        errors: null
      }
    })
}

export const fetchJoaMembersForChangeManager = (restrictOption = null) => {
  const params = {
    action_name: 'fetch',
    restrict_option: restrictOption
  }

  return axios.get('/ajax/joa_members/fetch_for_change_manager', { params })
    .then(res => {
      if (IS_DEVELOPMENT) {
        console.log('res', res)
      }
      if (res.data.success) {
        return res.data.joa_members
      }
      return null
    })
    .catch(err => {
      return null
    })
}

export const fetchJoaMembersByFreeword = (freeword, restrictOption = null) => {
  const params = {
    action_name: 'search',
    q: freeword,
    restrict_option: restrictOption
  }
  return axios.get(`/ajax/joa_members/fetch_for_change_manager`, { params })
    .then(res => {
      if (IS_DEVELOPMENT) {
        console.log('res', res)
      }
      if (res.data.success) {
        return res.data.joa_members
      }
      return null
    })
    .catch(err => {
      return null
    })
}

export const fetchJoaMembersByFacility = () => {
  return axios.get('/ajax/joa_members/facility')
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        return res.data.joa_members
      }
      return null
    })
    .catch(err => {
      console.log('err', err)
      return null
    })
}

export const fetchJoaMembersBySearch = (firstName, lastName) => {
  const query = `?first_name=${encodeURIComponent(firstName)}&last_name=${encodeURIComponent(lastName)}`
  return axios.get(`/ajax/joa_members/search${query}`)
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        return res.data.joa_members
      }
      return null
    })
    .catch(err => {
      console.log('err', err)
      return null
    })
}

export const fetchIcd10Code = ({depth = 1, id = null, skip = false} = {}) => {
  const params = {
    depth,
    parent: id,
    skip
  }
  const url = depth === 1 ? 'icd10/root' : 'icd10'
  return axios.get(`/ajax/${url}`, { params })
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return null
    })
}

export const fetchIcd10CodeBySearch = (search = '') => {
  const params = {
    q: encodeURIComponent(search)
  }
  return axios.get('/ajax/icd10/search', { params })
  .then(res => {
    console.log('res', res)
    if (res.data.success) {
      return res.data.data
    }
    return null
  })
  .catch(err => {
    return null
  })
}

export const fetchDiseaseNameBySearch = (search = '') => {
  return axios.get('/ajax/medis_byomei/search', { params: { q: encodeURIComponent(search) } })
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return null
    })
}

export const fetchIcd10CodeOften = (sheetKindId, organizationId, parentId = null) => {
  const params = {
    sheet_kind_id: sheetKindId,
    organization_id: organizationId,
    parent: parentId
  }
  return axios.get('/ajax/icd10/often_icd10s', { params })
    .then(res => {
      if (res.data.success) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return null
    })
}

// よく使用される術式
export const fetchOftenMethods = (caseCode) => {
  const params = {
    case_code: caseCode
  }
  return axios.get('/ajax/ope_method/often_methods', { params })
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        return res.data.data
      }

      return null
    })
    .catch(err => {
      return null
    })
}

// 術式検索
const fetchSskMedicalPractices = ({caseCode = null, freeword = null} = {}) => {
  let params = {
    case_code: caseCode,
    q: freeword
  }
  return axios.get('/ajax/ope_method/medical_practices', { params })
    .then(res => {
      if (res.data.success) {
        return res.data.data
      }
      return null
    })
    .catch(err =>  null)
}

export const fetchSskMedicalPracticesCurrentFY = (freeword = null) => {
  return fetchSskMedicalPractices({freeword: freeword})
}

export const fetchSskMedicalPracticesWithCaseCode = (caseCode, freeword = null) => {
  return fetchSskMedicalPractices({caseCode: caseCode, freeword: freeword})
}

// 商品
export const fetchItems = (key) => {
  const query = `?key=${key}`
  return axios.get(`/ajax/items${query}`)
    .then(res => {
      console.log('res', res)
    })
    .catch(err => {
      return null
    })
}

// 設置年月日
export const fetchInstallationDate = (key) => {
  const query = `?key=${key}`
  return axios.get(`/ajax/installation_date${query}`)
    .then(res => {
      console.log('res', res)
    })
    .catch(err => {
      return null
    })
}

export const createSubCase = (caseId, date) => {
  return axios.post(`/cases/${caseId}/sub`, { date })
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        window.location.href = res.data.url;
      }
      return { isSuccess: res.data.success, error: res.data.error }
    })
    .catch(err => {
      return {
        isSuccess: false,
        error: `副調査票作成に失敗しました。${ERROR_MESSAGES.RETRY_LATER}`
      }
    })
}

export const searchItems = (search = '', key, sheetKindId, organizationId) => {
  const params = {
    data_key: key,
    sheet_kind_id: sheetKindId,
    organization_id: organizationId,
    q: encodeURIComponent(search)
  }
  return axios.get('/ajax/items/search', { params })
    .then(res => {
      console.log('res', res)
      if (res.data.success) {
        return res.data.data
      }
      return []
    })
    .catch(err => {
      console.log('err', err)
      return null
    })
}

export const searchBarcodes = (search = '', key, sheetKindId, organizationId) => {
  const params = {
    data_key: key,
    sheet_kind_id: sheetKindId,
    organization_id: organizationId,
    raw: encodeURIComponent(search)
  }
  return axios.get('/ajax/barcode_items/search', { params })
    .then(res => {
      if (res.data) {
        return res.data
      }
      return {}
    })
    .catch(err => {
      return null
    })
}


export const fetchBarcodeFolders = (caseCode) => {
  const slug = caseCode.replace(/_/g, "-")
  return axios.get(`/cases/${slug}/barcodes/folders`)
    .then(res => {
      if (res.data.success) {
        return res.data.folders
      }
    })
    .catch(err => {
      return null
    })
}

export const sendSelectBarcodeFolder = (caseCode, params) => {
  const slug = caseCode.replace(/_/g, "-")
  return axios.post(`/cases/${slug}/barcodes/choose`, params)
    .then(res => {
      if (res.data) {
        return res.data
      }
    })
    .catch(err => {
      return null
    })
}

export const sendApplyBarcodeFolder = (caseCode, params) => {
  const slug = caseCode.replace(/_/g, "-")
  return axios.post(`/cases/${slug}/barcodes/apply`, params)
    .then(res => {
      if (res.data.success) {
        return true
      }
    })
    .catch(err => {
      return null
    })
}


export const fetchMakers = (key, sheetKindId, organizationId) => {
  const params = {
    data_key: key,
    organization_id: organizationId,
    sheet_kind_id: sheetKindId
  }
  return axios.get('/ajax/items/maker', { params })
    .then(res => {
      if (res.data.success) {
        return res.data.data
      }
    })
    .catch(err => {
      return null
    })
}

export const fetchMakerItems = (makerName, key, sheetKindId, organizationId) => {
  const params = {
    data_key: key,
    maker_name: encodeURIComponent(makerName),
    organization_id: organizationId,
    sheet_kind_id: sheetKindId
  }
  return axios.get('/ajax/items/maker_items', { params })
    .then(res => {
      if (res.data.success) {
        return res.data.data
      }
    })
    .catch(err => {
      return null
    })
}

// admin route apis below
export const fetchDedications = (societyId, sheetKindId) => {
  const params = {
    society_id: societyId,
    sheet_kind_id: sheetKindId
  }
  return axios.get('/medical_equipments/dedications', { params })
    .then(res => {
      if (res.data.success) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return null
    })
}

// labo route apis below ------------------
export const getIcd10FromOpeMethod = ({ kcode = null, dpc = null }) => {
  const params = { kcode, dpc_code: dpc }
  return axios.get('/labo/kcode_to_icd10s', {params, headers: {'X-Requested-With': 'XMLHttpRequest' }})
    .then(res => {
      if (res.data.success) {
        return res.data.data
      }
      return null
    })
    .catch(err => {
      return null
    })
}

export const sendTraceabilitySkipRenewalRequest = () => {
  setCSRFToken()
  return axios.put(`/ajax/facilities/selected/traceability/skip_renewal.json`)
    .then(res => {
      if (res.data.success) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      return false
    })
}

export const sendCasesAuthenticationCode = () => {
  return axios.get(`/ajax/cases_authentication/send_code`)
    .then(handleSuccess())
    .catch(handleFailure)
}

export const postCasesAuthenticationCode = (code) => {
  setCSRFToken()
  return axios.put(`/ajax/cases_authentication`, { code })
    .then(handleSuccess())
    .catch(handleFailure)
}

export const startCasesDownload = (sheetKindId, casesDownloadType, from, to) => {
  const params = {
    sheet_kind_id: sheetKindId,
    cases_download_type: casesDownloadType,
    from,
    to
  }
  return axios.post(`/ajax/cases_download/start`, params)
    .then(handleSuccess((data) => {
      return {
        downloadLogId: data.download_log_id
      }
    }))
    .catch(handleFailure)
}

export const prepareCasesDownload = (downloadLogId) => {
  const params = {
    download_log_id: downloadLogId,
  }
  return axios.get(`/ajax/cases_download/prepare`, { params })
    .then(handleSuccess((data) => {
      return {
        statusText: data.status_text
      }
    }))
    .catch(handleFailure)
}

export const finishCasesDownload = (downloadLogId) => {
  const params = {
    download_log_id: downloadLogId,
  }
  return axios.get(`/ajax/cases_download/finish`, { params })
    .then(handleSuccess((data) => {
      return {
        downloadUrl: data.download_url,
        fileName: data.file_name,
      }
    }))
    .catch(handleFailure)
}

const handleSuccess = (responseParser = null) => {return (res) => {
  if (res.data.success) {
    return {
      success: true,
      error: null,
      data: responseParser ? responseParser(res.data.data) : null
    }
  }
  return {
    success: false,
    error: new Error(res.data.error),
    data: null
  }
}}

const handleFailure = (err) => {
  return {
    success: false,
    error: err,
    data: null
  }
}
