<template lang="pug">
  .modal.joa-member-modal(:class="{'is-active': showJoaMemberModal}")
    .modal-background(@click="handleModalClose")
    .modal-card
      header.modal-card-head.has-background-white.has-text-centered
        p.modal-card-title.is-size-6 {{ modaltitle }}
        span.close(@click="handleModalClose")
      section.modal-card-body
        .search-div
          .search-row
            .search-label-item
              p.search-title フリーワードで検索
            .search-form-item.columns
              .column
                input.input(
                  type="search"
                  @input="onChangeInput"
                  @compositionstart="composing=true"
                  @compositionend="composing=false"
                  name="freeword"
                  v-model="state.freeword"
                )
        .suggest-div
          .suggest-title
            img.search-icon(:src="$options.icons.search")
            span.has-text-weight-bold.is-fsize-14px 検索結果
            span.is-fsize-13px(v-show="!state.isSearched") {{ searchResultDesc }}
          //- render if it has results
          .suggest-row-wrapper(v-if="state.suggests.length")
            .suggest-row(
              v-for="member in state.suggests"
              :key="member.id"
              @click="onClickSuggest(member)"
            )
              .member-name
                span {{ member.full_name }}
              .facility-name
                span {{ member.facility }}
              .joanr-registration-status
                span {{ getJoanrStatus(member) }}
          //- render if it has NO results and a user searched
          p.no-result(v-else-if="!state.suggests.length && state.isSearched") 検索結果が見つかりませんでした。

</template>

<script>
  import debounce from "lodash/debounce"
  import { fetchJoaMembersForChangeManager, fetchJoaMembersByFreeword } from "../../utils/api";
  import iconSearch from "images/icons/icon-search_black.png";
  export default {
    icons: {
      search: iconSearch
    },
    props: ['restrictOption','showJoaMemberModal'],
    data() {
      return {
        state: {
          suggests: [],
          freeword: '',
          isSearched: false
        },
        composing: false
      }
    },
    watch: {
      async showJoaMemberModal(val) {
        if (val) {
          const members = await fetchJoaMembersForChangeManager(this.restrictOption);
          if (members) {
            this.state.suggests = members
          }
        }
      }
    },
    computed: {
      modaltitle() {
        if (this.restrictOption === 'facility') {
          return 'JOA会員一覧から選択（施設内）'
        } else if (this.restrictOption === 'external') {
          return 'JOA会員一覧から選択（施設外）'
        }
        return ''
      },
      searchResultDesc() {
        if (this.restrictOption === 'facility') {
          return '（所属施設のJOA会員一覧）'
        } else if (this.restrictOption === 'external') {
          return '（所属施設以外のJOA会員一覧（50件を表示））'
        }
        return ''
      }
    },
    methods: {
      handleModalClose() {
        this.state = {
          suggests: [],
          freeword: '',
          isSearched: false
        }
        this.$emit('toggle-modal')
      },
      onChangeInput: debounce(
        function(event) {
          if (this.composing) return

          const { value, name } = event.target;
          this.state[name] = value
          this.searchMembers()
      }, 200),
      async searchMembers() {
        const hasSearchWord = !!this.state.freeword
        const members = hasSearchWord ? await fetchJoaMembersByFreeword(this.state.freeword, this.restrictOption) : await fetchJoaMembersForChangeManager(this.restrictOption);
        if (members) {
          this.state.suggests = members
        }
        this.state.isSearched = hasSearchWord
      },
      onClickSuggest(member) {
        this.$emit('select-member', member)
        this.state = {
          suggests: [],
          freeword: '',
          isSearched: false
        }
      },
      getJoanrStatus(member) {
        if (member.joanr_started) {
          return 'JOANR登録済み'
        } else {
          return ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .joa-member-modal {
    .search-div {
      position: relative;
      padding: 0 10px;
      .search-row {
        display: flex;
        align-items: center;
      }
      .search-label-item {
        width: 30%;
        display: flex;
        .search-title {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .search-form-item {
        display: flex;
        flex: 1;
        .name-search-half {
          display: flex;
          align-items: center;
          span {
            margin-right: 12px;
            font-size: 14px;
          }
          input {
            width: 90%;
          }
        }
      }
    }
    .suggest-div {
      position: relative;
      padding: 16px 10px 0;
      .suggest-title {
        display: flex;
        align-items: center;
        color: #333;
        .search-icon {
          max-width: 13px;
          height: auto;
          margin-right: 4px;
        }
      }
      .suggest-row-wrapper {
        border-top: 1px solid #D7D7D7;
        border-bottom: 1px solid #D7D7D7;
        max-height: 340px;
        overflow-y: scroll;
        .suggest-row {
          display: flex;
          align-items: center;
          padding: 8px 10px;
          cursor: pointer;
          &:hover {
            background-color: #EFEFEF;
          }
          &:not(:last-child) {
            border-block-end: 1px solid #D7D7D7;
          }

          .member-name {
            display: flex;
            width: 25%;
            padding-right: 8px;
            span {
              font-size: 14px;
              word-break: break-all;
              line-height: 14px;
            }
          }
          .facility-name {
            display: flex;
            width: 50%;
            padding-right: 8px;
            span {
              font-size: 12px;
              word-break: break-all;
              line-height: 14px;
            }
          }
          .joanr-registration-status {
            display: flex;
            width: 25%;
            padding-right: 8px;
            span {
              font-size: 12px;
              word-break: break-all;
              line-height: 14px;
              &.prefix {
                padding-right: 10px;
              }
            }
          }
        }
      }
      .no-result {
        padding: 8px 16px;
        color: #AAAAAA;
        font-size: 13px;
      }
    }
  }
</style>
