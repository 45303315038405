import Vue from 'vue/dist/vue.esm'
import { VTooltip } from 'v-tooltip'
// utils
import { ready } from 'src/utils/helpers'
import { setCSRFToken } from 'src/utils/authentication'
// componenets
import draggable from 'vuedraggable'
import UserProceduresModal from 'src/components/Modals/UserProceduresModal'

ready(() => {
  const id_name = 'user-procedures'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    setCSRFToken()
    new Vue({
      el: wrapper,
      components: {
        draggable,
        UserProceduresModal,
      },
      directives: {
        tooltip: VTooltip
      },
      mounted() {
        window.onbeforeunload = ((e) => {
          if (this.isDragged) {
            e.preventDefault();
            e.returnValue = '';
          }
        })
      },
      data() {
        return {
          isOpenModal: false,
          isDragged: false,
          isDraggable: false,
          isMouseOverCreateButton: false,
          isMouseOverSubmitButton: false,
        }
      },
      computed: {
        tableClass() {
          return {
            'is-striped': !this.isDraggable
          }
        },
        tbodyClass() {
          return {
            'has-draggable' : this.isDraggable,
          }
        },
        createButtonTooltip() {
          return {
            show: this.isDraggable && this.isMouseOverCreateButton,
            placement: 'bottom',
            trigger: 'manual',
            content: '並び替え操作中は登録できません',
          }
        },
        submitButtonTooltip() {
          return {
            show: !this.isDragged && this.isMouseOverSubmitButton,
            placement: 'bottom',
            trigger: 'manual',
            content: '並び順を変更してください',
          }
        }
      },
      methods: {
        toggleModal() {
          this.isOpenModal = !this.isOpenModal
        },
        toggleDraggable() {
          this.isDraggable = !this.isDraggable
        },
        onDrag(e) {
          // Changed sorting within list
          if (!this.isDragged) {
            this.isDragged = true
          }
        },
        onCreate(selectedPracticeCode) {
          const cf = document.getElementById('create-form')
          cf.practice_code.value = selectedPracticeCode
          cf.submit()
        },
        onUpdateOrder() {
          this.isDragged = false
          const tbodyData = document.getElementById('tbody-data')
          if (tbodyData.rows.length > 0) {
            const uf = document.getElementById('update-form')
            tbodyData.rows.forEach((trElem, index) => {
              const practiceCodeField = document.createElement('input')
              practiceCodeField.type  = 'hidden'
              practiceCodeField.name  = `data[][practice_code]`
              practiceCodeField.value = trElem.id

              const dispOrderField = document.createElement('input')
              dispOrderField.type  = 'hidden'
              dispOrderField.name  = `data[][disp_order]`
              dispOrderField.value = index

              uf.appendChild(practiceCodeField)
              uf.appendChild(dispOrderField)
            })
            document.body.appendChild(uf);
            uf.submit()
          }
        }
      }
    })
  }
})
