
import Vue from 'vue/dist/vue.esm'

import SearchJoaMembersModal from 'src/components/Modals/SearchJoaMembersModal'
import { setCSRFToken } from "src/utils/authentication"

document.addEventListener('DOMContentLoaded', () => {
  const id_name = 'current-initiative-new'
  const wrapper =  document.getElementById(id_name)
  if (wrapper) {
    setCSRFToken()
    const props = JSON.parse(wrapper.getAttribute('data'))
    new Vue({
      el: wrapper,
      components: {
        SearchJoaMembersModal
      },
      data() {
        return {
          showJoaMemberModal: false,
          selectedDoctor: props.selectedDoctor,
          restrictOption: null,
          doctorExperienceOptions: props.doctorExperienceOptions
        }
      },
      mounted() {
        let restrictOptionField = document.getElementById("change_manager_request_restrict_option")
        if (restrictOptionField) {
          this.restrictOption = restrictOptionField.value
        }
      },
      computed: {
        selectedDoctorInfo() {
          if (this.selectedDoctor) {
            return this.selectedDoctor.full_name
          }
          return ''
        },
        computedJoaMemberUserIdField() {
          if (this.selectedDoctor) {
            return this.selectedDoctor.id
          }
          return ''
        }
      },
      methods: {
        onSelectMember(e) {
          this.selectedDoctor = e

          let joaMemberUserIdField = document.getElementById("change_manager_request_joa_member_user_id")
          joaMemberUserIdField.value = this.computedJoaMemberUserIdField

          this.toggleSearchJoaMembersModal()
        },
        toggleSearchJoaMembersModal() {
          this.showJoaMemberModal = !this.showJoaMemberModal
        },
      }
    })
  }
})
