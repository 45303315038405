import Vue from 'vue/dist/vue.esm'

const TAB_CATEGORY_ALL = 'all'
const TAB_CATEGORY_GENERAL = 'general'
const TAB_CATEGORY_MAINTENANCE = 'maintenance'

document.addEventListener('DOMContentLoaded', () => {
  const id_name = 'top'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    new Vue({
      el: wrapper,
      data() {
        return {
          activeTab: TAB_CATEGORY_ALL
        }
      },
      computed: {
        linkUrl() {
          const baseUrl = '/news'
          if(this.activeTab === TAB_CATEGORY_ALL) {
            return baseUrl
          } else {
            return baseUrl + '?type=' + this.activeTab
          }
        },
        linkText() {
          if (this.activeTab === TAB_CATEGORY_GENERAL) {
            return 'お知らせ一覧をみる'
          } else if (this.activeTab === TAB_CATEGORY_MAINTENANCE) {
            return 'メンテナンス一覧をみる'
          } else {
            return 'すべてのお知らせ一覧をみる'
          }
        }
      },
      methods: {
        onClickTab(clickedTab) {
          if (clickedTab === TAB_CATEGORY_GENERAL) {
            this.activeTab = TAB_CATEGORY_GENERAL
          } else if (clickedTab === TAB_CATEGORY_MAINTENANCE) {
            this.activeTab = TAB_CATEGORY_MAINTENANCE
          } else {
            this.activeTab = TAB_CATEGORY_ALL
          }
          document.getElementById('news-body').scrollTop = 0
        }
      }
    })
  }
})
