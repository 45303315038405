import axios from 'axios'
import get from 'lodash/get'

export const setAxiosDefaults = () => {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  setAxiosInterceptors()
  axios.defaults.baseURL = `/${getBaseUrl()}`
}

const setAxiosInterceptors = () => {
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    // if session is timed out
    if (error.response && error.response.status === 401) {
      window.onbeforeunload = null
      const modal = document.getElementById('session-timeout-modal')
      modal.classList.add('is-active') // show modal
    } else {
      return Promise.reject(error);
    }
  });
}

export const getBaseUrl = () => {
  const app = document.getElementById('application-wrapper')
  if (app) {
    const appProps = JSON.parse(app.getAttribute('data'))
    const scope = get(appProps, 'scope')
    return scope || 'member'
  }
  return 'member'

}
