import Vue from 'vue/dist/vue.esm'
import ConfirmModal from 'src/components/Modals/ConfirmModal'

document.addEventListener('DOMContentLoaded', () => {
  const id_name = 'traceability'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    new Vue({
      el: wrapper,
      components: {
        ConfirmModal
      },
      data() {
        return {
          originalTraceabilityData: { ...sharedData.original_traceability_record },
          traceabilityData: sharedData.traceability_record,
          isEditPage: sharedData.is_edit_page || false,
          isFormCorrectConsent: false,
          isFormKnownByFacilityManager: false,
          errors: {},
          disableSubmitBtn: false,

          // ConfirmModal states
          showConfirmModal: false,
          confirmButton: {
            text: '更新',
            cancelText: "閉じる",
          },
          confirmModalMeta: {
            title: '確認',
            bodyText: '機関の長（病院長など）にも変更はございませんか？今一度、ご確認の上、更新を実行してください。'
          },
          handleClickConfirm: () => { this.submitForm() }
        }
      },
      mounted() {
        const val = this.traceabilityData["detailed_ic_type"]
        if (val) {
          this.updateIcType('detailed_ic_type', val)
        }
      },
      methods: {
        updateValue(name, value) {
          this.$set(this.traceabilityData, name, value)
        },
        isChecked(key, val) {
          if (key in this.traceabilityData) {
            return this.traceabilityData[key] === val
          }
          return false
        },
        updateIcType(name, value) {
          this.updateValue(name, value)

          const labels = document.getElementsByClassName('publicity_and_notification_label')
          for (var i = 0; i < labels.length; i++) {
            var label = labels[i]
            label.classList.remove('disabled')
            var elem_input = label.getElementsByTagName('input')[0]
            elem_input.disabled = false
          }

          switch (this.traceabilityData['detailed_ic_type'])
          {
            case 'document':
            case 'oral':
              this.__disable_publicity_and_notif_button([
                'publicity_and_notification_label_implemented_with_optout',
                'publicity_and_notification_label_other'
              ])
              break
            case 'oral_anonymized_processed':
              this.__disable_publicity_and_notif_button([
                'publicity_and_notification_label_nonfulfillment',
                'publicity_and_notification_label_other'
              ])
              break
            case 'optout':
              this.__disable_publicity_and_notif_button([
                'publicity_and_notification_label_nonfulfillment',
                'publicity_and_notification_label_implemented',
                'publicity_and_notification_label_other'
              ])
              break
            case 'oral_anonymized_person':
            case 'oral_anonymized_or_unidentified':
            case 'other_method':
              this.__disable_publicity_and_notif_button([
                'publicity_and_notification_label_nonfulfillment',
                'publicity_and_notification_label_implemented',
                'publicity_and_notification_label_implemented_with_optout',
                'publicity_and_notification_label_other'
              ])
              break
          }
        },
        updateConsent(e) {
          const { name } = e.target
          this[name] = !this[name]
        },
        isConsentChecked(key) {
          return this[key]
        },
        handleSubmitBtnClick() {
          // 更新画面または新規登録時かつ同意にチェックがついている場合にsubmitが可能
          if (this.isEditPage) {
            // 編集時は変更の有無を確認する
            if (this.hasChanged()) {
              this.submitForm()
            } else {
              // 変更がない場合には確認を表示
              this.toggleConfirmModal()
            }
          } else if (this.isFormCorrectConsent && this.isFormKnownByFacilityManager) {
            this.disableSubmitBtn = true
            this.submitForm()
          } else {
            this.$set(this.errors, 'consent', '下記に同意してください')
          }
        },
        submitForm() {
          const form = document.getElementById('form')
          form.submit()
        },
        hasChanged() {
          const keys = Object.keys(this.originalTraceabilityData)
          const changedKeys = keys.filter(k => {
            return this.originalTraceabilityData[k] !== this.traceabilityData[k]
          })
          return !!changedKeys.length
        },
        __disable_publicity_and_notif_button(disable_elems) {
          disable_elems.forEach(id_name => {
            const elem = document.getElementById(id_name)
            const elem_input = elem.getElementsByTagName('input')[0]
            elem.classList.add('disabled')
            elem_input.disabled = true
            if (elem_input.checked) {
              elem_input.checked = false
            }
          })
        },

        toggleConfirmModal() {
          this.showConfirmModal = !this.showConfirmModal
        },
      }
    })
  }
})
