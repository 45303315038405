import { TEXT_INPUT_COMPONENT_TYPES } from './constants'

export const closeFlashMessage = () => {
  const flashMessage = document.getElementById('flash-message')
  flashMessage.style.display = 'none'
}

export const isTextInputComponentType = (componentType) => {
  return TEXT_INPUT_COMPONENT_TYPES.indexOf(componentType) !== -1
}

export const isMsIeOrEdge = () => {
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  return isIE || isEdge;
}

export const getConsecutiveNumbers = (start, end) => {
  let nums = []
  for (let i = start; i <= end; i++) {
    nums.push(i)
  }
  return nums
}

export const ready = (fn) => {
  if (document.readyState != 'loading'){
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

export const setScrollToTopEvent = (elem_id) => {
  $(elem_id).hide();
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 100) {
      $(elem_id).fadeIn("fast");
    } else {
      $(elem_id).fadeOut("fast");
    }
    const scrollHeight = $(document).height();
    const scrollPosition = $(window).height() + $(window).scrollTop();
    const footHeight = $("footer").innerHeight();
    if ( scrollHeight - scrollPosition  <= footHeight ) {
      $(elem_id).css({
        "position": "absolute",
        "bottom": 0,
      });
    } else {
      $(elem_id).css({
        "position":"fixed",
        "bottom": "20px",
      });
    }
  });
  $(elem_id).click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 400);
      return false;
  });
}
