<template lang="pug">
  .button-input-wrapper
    label.button-label(
      v-for="obj in data" 
      :key="obj.value || obj.id"
    )
      input(
        :type="type"
        :name="name"
        :checked="(obj.value || obj.id) === value"
        @change="$emit('update-button', name, (obj.value ? obj.value : obj.id))"
      )
      span {{ obj.name }}
</template>

<script>
  export default {
    props: ['data', 'type', 'name', 'value']
  };
</script>

<style lang="scss" scoped>
  .button-input-wrapper {
    overflow: auto;
  }
</style>
