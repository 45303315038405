<template lang="pug">
  .modal.delete-modal(:class="{'is-active': showDeleteModal}")
    .modal-background(@click="$emit('toggle-modal')")
    .modal-card.delete-modal-card
      header.modal-card-head.has-background-white.has-text-centered
        p.modal-card-title.is-size-6 {{ title }}
        span.close(@click="$emit('toggle-modal')")
      section.modal-card-body
        p(v-html="body")
        .buttons-wrapper
          p.delete-error.has-text-red.is-fsize-13px(v-show="deleteError") {{ deleteError }}
          button.button.cancel-btn(@click="$emit('toggle-modal')") キャンセル
          button.button.delete-btn(
            v-if="!deleteHrefPath"
            key="delete-button"
            @click="handleDeleteClick"
          ) 削除
          a.button.delete-btn(
            v-else
            key="delete-button"
            data-method="delete"
            :href="deleteHrefPath"
            :class="disableButton && 'disabled'"
            @click="handleDeleteClick"
          ) 削除
</template>

<script>
  export default {
    props: ['showDeleteModal', 'title', 'body', 'deleteHrefPath', 'deleteError'],
    data() {
      return {
        disableButton: false
      }
    },
    methods: {
      handleDeleteClick() {
        if (!this.deleteHrefPath) {
          this.$emit('click-delete')
        }
        this.$emit('toggle-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/common/variables.scss';
  @import '../../../styles/common/mixins.scss';

  .delete-modal-card {
    box-shadow: 8px 8px 5px rgba(#000, 0.3);
    width: 558px;
    .modal-card-head {
      position: relative;
      border-radius: 0;
      justify-content: center;
      padding: 16px;
      .close {
        @include create-close-icon(24px, 3px, $black);
        position: absolute;
        right: 32px;
        cursor: pointer;
      }
    }
    footer {
      border-radius: 0;
    }
    .modal-card-body {
      p {
        font-size: 14px;
        text-align: center;
      }
      .buttons-wrapper {
        text-align: center;
        padding: 32px 0 16px;
        .delete-error {
          padding-bottom: 6px;
        }
        button, a {
          width: 165px;
          height: 38px;
          border-radius: 0;
          font-size: 16px;
          margin: 0 6px;
          border: none;
          &.cancel-btn {
            background-color: #F7F7F7;
            color: $black;
            box-shadow: 0px 2px darken(#F7F7F7, 15%);
            &:hover {
              @include set-hover-color(#F7F7F7);
            }
          }
          &.delete-btn {
            background-color: $red;
            color: $white;
            box-shadow: 0px 2px darken($red, 15%);
            &:hover {
              @include set-hover-color($red);
            }
          }
        }
      }
    }
  }
</style>
