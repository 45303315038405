import Vue from 'vue/dist/vue.esm'
import { ready } from 'src/utils/helpers'
import AcceptConfirmModal from 'src/components/Modals/ConfirmModal'
import DeclineConfirmModal from 'src/components/Modals/ConfirmModal'

ready(() => {
  const id_name = 'new-initiative-edit'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    const props = JSON.parse(wrapper.getAttribute('data'))
    new Vue({
      el: wrapper,
      components: {
        AcceptConfirmModal,
        DeclineConfirmModal
      },
      computed: {
        acceptConfirmModalBody() {
          return `「${props.facility.name}」の医長交代申請を承認します。<br/>承認後も医長交代日までは医長としてご利用いただけます。`
        }
      },
      data() {
        return {
          showAcceptConfirmModal: false,
          showDeclineConfirmModal: false,
          acceptConfirmButton: {
            text: '承認する'
          },
          declineConfirmButton: {
            text: '否認する',
            hrefPath: '',
            hrefMethod: 'delete',
            'is-danger': true,
          },
        }
      },
      methods: {
        toggleAcceptConfirmModal() {
          this.showAcceptConfirmModal = !this.showAcceptConfirmModal
        },
        toggleDeclineConfirmModal() {
          this.showDeclineConfirmModal = !this.showDeclineConfirmModal
        },
        onClickAcceptButton() {
          this.toggleAcceptConfirmModal()
        },
        onClickDeclineButton(path) {
          this.declineConfirmButton.hrefPath = path
          this.toggleDeclineConfirmModal()
        },
        onConfirmedAcceptButton() {
          const form = document.getElementById('new-initiative-edit-form')
          form.submit()
        },
      }
    })
  }
})
