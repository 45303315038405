import Vue from 'vue/dist/vue.esm'
import { ready } from 'src/utils/helpers'

ready(() => {
  const id_name = 'change-manager-index'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    new Vue({
      el: wrapper,
      data() {
        return {
          opendSections: []
        }
      },
      methods: {
        toggleSection(key) {
          if (this.opendSections.includes(key)) {
            this.opendSections = this.opendSections.filter(v => v != key)
          } else {
            this.opendSections.push(key)
          }
        },
        isShowSection(key) {
          return this.opendSections.includes(key)
        }
      }
    })
  }
})
