import Vue from 'vue/dist/vue.esm'
import { ready } from 'src/utils/helpers'

ready(() => {
  const v = document.querySelector('video#vid')
  if (v) {
    v.addEventListener('error', (e) => {
      if (!alert('動画の読み込みに失敗しました。ブラウザを更新します。')) {
        window.location.reload()
      }
    })
  }

  const id_name = 'content-slides'
  const el_slides = document.getElementById(id_name)
  if (el_slides) {
    const props = JSON.parse(el_slides.getAttribute('data'))

    new Vue({
      el: `#${id_name}`,
      data() {
        return {
          content: props.content || '',
          currentSlide: 0,
          slides: []
        }
      },
      mounted() {
        this.setSlides()
      },
      methods: {
        setSlides() {
          var loaded = []
          if (this.content === "joanr") {
            loaded = require.context(`images/manual/joanr/`, true, /\.png$/)
          }
          else if (this.content === "ic") {
            loaded = require.context(`images/manual/ic/`, true, /\.png$/)
          }

          const images = []
          loaded.keys().forEach(key => images.push(loaded(key).default))

          this.slides = images
        },
        previousPage() {
          var newSlide = this.currentSlide - 1
          if (newSlide < 0) {
            newSlide = 0
          }
          this.currentSlide = newSlide
        },
        nextPage() {
          var newSlide = this.currentSlide + 1
          if (newSlide > this.slides.length - 1) {
            newSlide = this.slides.length - 1
          }
          this.currentSlide = newSlide
        }
      }
    })
  }
})
