import Vue from 'vue/dist/vue.esm'
import { setScrollToTopEvent } from 'src/utils/helpers'

document.addEventListener('DOMContentLoaded', () => {
  const id_name = 'about-jyutsushiki'
  const wrapper = document.getElementById(id_name)

  if(wrapper) {
    new Vue({
      el: wrapper,
      mounted: function() {
        setScrollToTopEvent('#to-top-button')
      },
    })
  }
})
