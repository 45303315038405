<template lang="pug">
  .modal(:class="{'is-active': isOpen }")
    .modal-background(@click="emitToggle")
    .modal-card
      header.modal-card-head
        p.modal-card-title
          | 術式登録
        span.close(@click="emitToggle")

      section.modal-card-body
        p.title
          | 術式（Kコード）を選択
        .section-row
          .section-row__label
            | リストから選択
          SelectWithDropdown(
            name="selectedListItem"
            :value="selectedListItem"
            :options="listOptions"
            placeholder="Kコードを選択してください"
            @select-item="onSelectedListItem"
          )

        .section-row(v-on-clickaway="onClickOutside")
          .section-row__label
            | フリーワードで検索
          input.input(
            type="search"
            name="freeword"
            v-model="freeword"
            placeholder="術式名またはKコードを入力してください"
            @input="onInput"
            @compositionstart="composing=true"
            @compositionend="composing=false"
          )
          .suggests-wrapper(v-show="isOpenSuggestBox")
            .suggests-wrapper-inner(ref="suggestBox")
              .suggest-row(
                v-for="(item, i) in searchResultOptions"
                :key="item.key"
                class="suggest-row"
                :class="{ 'is-active': i === activeSuggestRowIndex }"
                @click="onSelectedSearchItem(item)"
                @mouseover="activeSuggestRowIndex = i"
              )
                p.ope-method-name {{ item.name }}
              p.ope-method-not-found(v-if="searchResultOptions.length === 0")
                | 手術手技が見つかりませんでした。
                br
                | 検索条件を変えて、もう一度検索してください。

      footer.modal-card-foot
        .modal-card-foot__inner
          p.error-message(v-show="errorMessage") {{ errorMessage }}
          .button-groups
            button.button.button--cancel(@click="emitToggle")
              | キャンセル
            .tooltip-wrapper(
              v-tooltip="submitButtonTooltip"
              @mouseover="isMouseOverSubmitButton = true"
              @mouseleave="isMouseOverSubmitButton = false"
            )
              button.button.button--submit(@click="emitSubmit" :disabled="!computedPayload")
                | 登録する

</template>

<script>
  import debounce from 'lodash/debounce'
  import { VTooltip } from 'v-tooltip'
  import { mixin as clickaway } from 'vue-clickaway'
  // componenets
  import SelectWithDropdown from '../SelectWithDropdown'
  // utils
  import { fetchSskMedicalPracticesCurrentFY } from '../../utils/api'
  import { ERROR_MESSAGES } from "../../utils/constants"

  export default {
    components: {
      SelectWithDropdown
    },
    props: ['isOpen'],
    mixins: [ clickaway ],
    directives: {
      tooltip: VTooltip
    },
    data() {
      return {
        composing: false,
        errorMessage: null,
        freeword: '',
        selectedListItem: null,
        selectedSearchItem: null,
        listOptions: [],
        searchResultOptions: [],
        isOpenSuggestBox: false,
        activeSuggestRowIndex: -1,
        isMouseOverSubmitButton: false,
      }
    },
    computed: {
      computedPayload() {
        if (this.selectedListItem) {
          return this.selectedListItem.practice_code
        } else if (this.selectedSearchItem) {
          return this.selectedSearchItem.practice_code
        } else {
          return null
        }
      },
      submitButtonTooltip() {
        return {
          show: !this.computedPayload && this.isMouseOverSubmitButton,
          placement: 'bottom',
          trigger: 'manual',
          content: '術式を選択してください',
        }
      }
    },
    async mounted() {
      const smp = await fetchSskMedicalPracticesCurrentFY();
      if (smp) {
        this.listOptions = smp;
      } else {
        this.setErrorMessage(`データの取得に失敗しました。${ERROR_MESSAGES.RETRY_LATER}`)
      }
    },
    methods: {
      emitToggle() {
        this.$emit('toggle-modal')
      },
      emitSubmit() {
        this.$emit('on-submit', this.computedPayload)
      },
      onInput: debounce(
        async function(e) {
          if (this.composing) return

          const { value } = e.target
          if (value.trim() !== '') {
            this.freeword = value
            this.isOpenSuggestBox = true
            const res = await fetchSskMedicalPracticesCurrentFY(this.freeword)
            if (res) {
              this.searchResultOptions = res
              this.setErrorMessage()
              this.$nextTick(() => {
                if (this.$refs.suggestBox) {
                  this.$refs.suggestBox.scrollTop = 0
                }
              })
            } else {
              this.searchResultOptions = []
              this.setErrorMessage(`データの取得に失敗しました。${ERROR_MESSAGES.RETRY_LATER}`)
            }
          } else {
            this.freeword = ''
            this.selectedSearchItem = null
            this.isOpenSuggestBox = false
            this.searchResultOptions = []
          }
        }
      , 200),
      onSelectedListItem(name, value) {
        this.selectedListItem = value
        if (this.freeword !== '') {
          this.freeword = ''
          this.selectedSearchItem = null
        }
      },
      onSelectedSearchItem(item) {
        this.isOpenSuggestBox = false
        this.freeword = item.name
        this.selectedSearchItem = item
        if (this.selectedListItem !== null) {
          this.selectedListItem = null
        }
      },
      onClickOutside() {
        if (this.isOpenSuggestBox) {
          this.freeword = this.selectedSearchItem ? this.selectedSearchItem.name : ''
          setTimeout(() => {
            this.isOpenSuggestBox = false
          }, 0)
        }
      },
      setErrorMessage(error = '') {
        this.errorMessage = error
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../styles/common/variables";
  @import "../../../styles/common/mixins";

  .modal {
    .modal-card {
      overflow: visible;
      .modal-card-head {
        padding: 20px 16px;
        text-align: center;
        background-color: white;
        .modal-card-title {
          font-size: 1.25rem;
        }
        .close {
          top: 18px;
        }
      }

      .modal-card-body {
        overflow: visible;
        padding-left: 42px;
        padding-right: 42px;
        .title {
          border-left: 5px solid #1E5177;
          padding: 3px 3px 3px 7px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #1E5177;
          font-weight: 700;
        }
        .section-row {
          margin-bottom: 16px;
          &__label {
            font-size: 14px;
            color: $black;
            font-weight: bold;
            padding-bottom: 6px;
          }
        }
        .suggests-wrapper {
          position: relative;
          pointer-events: auto;
          .suggests-wrapper-inner {
            max-height: 200px;
            overflow-y: scroll;
            border: 1px solid $light-grey-2;
            background-color: $white;
            position: absolute;
            z-index: 100;
            width: 100%;
            .suggest-row {
              padding: 4px 8px;
              cursor: pointer;
              .ope-method-name {
                font-size: 14px;
              }
              &.is-active {
                background-color: $lighter-grey;
              }
            }
            .ope-method-not-found {
              text-align: center;
              padding: 10px 0;
              font-size: 14px;
              color: $grey;
            }
          }
        }
      }

      .modal-card-foot {
        display: flex;
        align-items: center;
        background-color: white;
        padding: 16px 42px 24px;
        &__inner {
          display: flex;
          flex-direction: column;
          flex: 1;
          align-items: flex-end;
          .error-message {
            font-size: 14px;
            color: $red;
            padding-bottom: 12px;
          }
          .button-groups {
            display: flex;
            .button {
              &--submit {
                border-radius: 0;
                @include create-action-button(165px, 38px, $white, $lighter-blue, 16px);
              }
              &--cancel {
                margin-right: 12px;
                border-radius: 0;
                border: 1px solid #E0E0E0;
                @include create-action-button(165px, 38px, $black, #F7F7F7, 16px);
              }
            }
          }

        }
      }
    }
  }
  .tooltip-wrapper {
    display:inline-block;
    .button[disabled] {
      pointer-events: none;
      opacity: .5;
    }
  }
</style>
