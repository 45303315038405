<template lang="pug">
  #session-timeout-modal.modal.timeout-modal
    .modal-background.timeout-modal-background
    .modal-card
      section.modal-card-body
        p.body-text 一定時間が経過したため、再度ログインが必要です
      footer.modal-card-footer.has-background-white
        .button-div
          button.button.ok-button(type="button" @click="handleOkClick") OK

</template>

<script>
  export default {
    methods: {
      handleOkClick() {
        window.location.reload()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../styles/common/variables.scss";
  @import "../../../styles/common/mixins.scss";
  .timeout-modal {
    padding-top: 120px;
    .timeout-modal-background {
      background-color: rgba(10, 10, 10, .9);
    }
  }
  .modal-card-body {
    padding-top: 48px;
    .body-text {
      font-size: 15px;
      text-align: center;
      padding: 0 64px;
    }
  }
  .modal-card-footer {
    display: flex;
    align-items: center;
    padding: 12px 32px 32px;
    justify-content: center;
    .button-div {
      display: flex;
      .ok-button {
        @include create-action-button(165px, 38px, $white, #1AABE1, 16px);
        border-radius: 0;
        margin-left: 8px;
      }
    }
  }
</style>
