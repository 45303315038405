import axios from 'axios'
import { setAxiosDefaults } from './axios'

export const setCSRFToken = () => {
  const tokenEl = document.getElementsByName('csrf-token')[0]
  if (tokenEl) {
    const token = tokenEl.getAttribute('content')
    axios.defaults.headers.common['X-CSRF-Token'] = token;
  }
  setAxiosDefaults()
}