<template lang="pug">
#traceability-renewal-modal.modal.traceability-renewal-modal(
  :class="{ 'is-active': showTraceabilityRenewalModal }"
)
  .modal-background.traceability-renewal-modal-background
  .modal-card
    header.modal-card-head.has-background-white.has-text-centered
      p.modal-card-title.is-size-6 トレーサビリティ情報の更新
    section.modal-card-body
      p.body-text.is-pre-wrap
        | トレーサビリティ情報の更新が必要です。
      p.body-text.is-pre-wrap(v-if="formattedDeadlineDate")
        | {{ formattedDeadlineDate }}までに更新されない場合、
        | 当該施設の症例登録および編集ができなくなります。
    footer.modal-card-footer.has-background-white
      .button-div
        button.button.skip-button(
          v-if="canSkip",
          type="button",
          @click="onSkipButtonClicked"
        ) あとで更新
        button.button.do-button(type="button", @click="onDoButtonClicked") 更新する
</template>

<script>
import moment from "moment";
import { sendTraceabilitySkipRenewalRequest } from "../../utils/api";

export default {
  props: ["canSkip", "deadlineDate", "traceabilityRenewalPath"],
  data() {
    return {
      showTraceabilityRenewalModal: true,
    };
  },
  computed: {
    formattedDeadlineDate() {
      return this.deadlineDate ? moment(this.deadlineDate).format("YYYY年M月D日") : null;
    },
  },
  methods: {
    onSkipButtonClicked() {
      sendTraceabilitySkipRenewalRequest();
      this.showTraceabilityRenewalModal = false;
    },
    onDoButtonClicked() {
      window.location.href = this.traceabilityRenewalPath;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/common/variables.scss";
@import "../../../styles/common/mixins.scss";
.traceability-renewal-modal {
  padding-top: 120px;
  .traceability-renewal-modal-background {
    background-color: rgba(10, 10, 10, 0.9);
  }
}
.modal-card-body {
  padding-top: 48px;
  .body-text {
    font-size: 15px;
    text-align: center;
    padding: 0 64px;
  }
}
.modal-card-footer {
  display: flex;
  align-items: center;
  padding: 12px 32px 32px;
  justify-content: center;
  .button-div {
    display: flex;
    .skip-button {
      @include create-action-button(165px, 38px, $black, #f7f7f7, 16px);
      border-radius: 0;
      margin-left: 8px;
    }
    .do-button {
      @include create-action-button(165px, 38px, $white, #1aabe1, 16px);
      border-radius: 0;
      margin-left: 8px;
    }
  }
}
</style>
