/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable"
import "regenerator-runtime/runtime"

import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/css/all.css'

import Rails from 'rails-ujs'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Vue from 'vue/dist/vue.esm'
import * as viewportUnitsBuggyfill from 'viewport-units-buggyfill'

Rails.start();

viewportUnitsBuggyfill.init();

if (FRONT_SENTRY_DSN_URL) {
  Sentry.init({
    dsn: FRONT_SENTRY_DSN_URL,
    integrations: [
      new Integrations.Vue({
        Vue
      })
    ]
  });

  Sentry.configureScope(scope => {
    scope.setTag('env', STAGE)
    scope.setTag('project', 'joanr')
    scope.setTag('scope', 'frontend')
  });
}
