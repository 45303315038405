import Vue from 'vue/dist/vue.esm'
// utils
import { ready } from 'src/utils/helpers'
// components
import DeleteModal from 'src/components/Modals/DeleteModal'

ready(() => {
  const id_name = 'barcodes-items-index'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    new Vue({
      el: wrapper,
      components: {
        DeleteModal,
      },
      data() {
        return {
          showDeleteModal: false,
          deleteHrefPath: ''
        }
      },
      methods: {
        toggleDeleteModal(path) {
          this.showDeleteModal = !this.showDeleteModal
          this.deleteHrefPath = path ? path : ''
        }
      }
    })
  }
})
