<template lang="pug">
  .modal(:class="{'is-active': showConfirmModal}")
    .modal-background(@click="$emit('toggle-modal')")
    .modal-card
      header.modal-card-head.has-background-white.has-text-centered
        p.modal-card-title.is-size-6 {{ title }}
        span.close(aria-label="close" @click="$emit('toggle-modal')")
      section.modal-card-body
        p.modal-card-body__text(v-html="body")
        .buttons-wrapper
          button.button.cancel-btn(
            type="button"
            @click="$emit('toggle-modal')"
          ) {{ confirmButton.cancelText || "キャンセル"}}
          a(
            v-if="confirmButton.hrefPath"
            key="confirm-button"
            class="button confirm-btn"
            :class="confirmButton['is-danger'] ? 'danger-btn' : ''"
            :href="confirmButton.hrefPath"
            :data-method="confirmButton.hrefMethod"
          ) {{ confirmButton.text }}
          button(
            v-else
            type="button"
            key="confirm-button"
            class="button confirm-btn"
            :class="confirmButton['is-danger'] ? 'danger-btn' : ''"
            @click="$emit('click-confirm')"
          ) {{ confirmButton.text}}
</template>


<script>
  export default {
    props: ['showConfirmModal', 'title', 'body', 'confirmButton']
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/common/variables.scss';
  @import '../../../styles/common/mixins.scss';

  .modal {
    .modal-card-body {
      &__text {
        font-size: 16px;
        text-align: center;
        padding: 0 32px;
      }
      .buttons-wrapper {
        text-align: center;
        padding: 32px 0 16px;
        button, a {
          margin: 0 6px;
          border: none;
          &.cancel-btn {
            @include create-action-button(165px, 38px, $black, #F7F7F7, 16px);
            border-radius: 0;
          }
          &.confirm-btn {
            @include create-action-button(165px, 38px, $white, #1AABE1, 16px);
            border-radius: 0;
          }
          &.danger-btn {
            @include create-action-button(165px, 38px, $white, $red, 16px);
            border-radius: 0;
          }
        }
      }
    }
  }
</style>
