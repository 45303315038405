import Vue from 'vue/dist/vue.esm'
import { ready } from 'src/utils/helpers'

ready(() => {
  const id_name = 'faq'
  const wrapper = document.getElementById(id_name)
  if (wrapper) {
    new Vue({
      el: wrapper,
      data() {
        return {
          openedIds: []
        }
      },
      methods: {
        toggleFaq(faqid) {
          const isOpened = this.openedIds.includes(faqid)
          if (isOpened) {
            this.openedIds = this.openedIds.filter(id => id !== faqid)
          } else {
            this.openedIds.push(faqid)
          }
        },
        showAnswer(faqid) {
          return this.openedIds.includes(faqid)
        }
      }
    })
  }
})
